export const TOKEN = 'customer-token';

export const ROUTES = {
  SIGNIN: '/login',
  DASHBOARD: '/dashboard',
  AUTH: '/login',
};

export const STRIPE_PUBLISHABLE_KEY = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;

// export const STATES = ["NSW", "VIC", "WA", "QLD", "SA", "TS", "ACT","TAS"]
export const STATES = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];

export const SUPPORTING_DOCUMENT_STATUS = {
  not_yet_started: 'Click to upload',
  not_yet_submitted: 'Not yet submitted',
  in_progress: 'Under review',
  completed: 'Satisfied',
};

export const SPECIAL_CONDITION_STATUS = {
  not_yet_started: 'Pending',
  in_progress: 'Pending',
  completed: 'Completed',
};

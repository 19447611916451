<template>
  <div>
    <!-- <h1 @click="openBasic">File Upload</h1> -->
    <Dialog
      :modal="true"
      :visible="display"
      position="top"
      :style="[isMR ? { width: '75vw' } : { width: '40vw' }]"
      :draggable="false"
    >
      <template #header>
        <h1 class="title pm-txt-color">File Upload</h1>
      </template>
      <div class="content">
        <p>{{ fromSnakeCase(taskTitle) }}</p>
        <p class="pm-txt-color heading">{{ externalDescription }}</p>
        <p class="pm-txt-color heading">
          Upload the required document by dragging it into the space below or click browse to select the file from your
          computer.
        </p>
        <div class="file" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDrop">
          <input
            type="file"
            multiple="multiple"
            name="fields[assetsFieldHandle][]"
            id="upload-field"
            class="w-px h-px opacity-0 overflow-hidden absolute"
            @click="onClick"
            @change="onChange"
            ref="fileV"
            accept=".pdf,.jpg,.jpeg,.png"
            :key="fileKey"
          />
          <label :data-test-id="getDataTestId('upload-input')" for="upload-field" class="upload-box">
            <div class="icon-text p-d-flex p-jc-center">
              <img class="icon" :src="require('@/assets/icons/upload_icon.svg')" alt="" />
              <p class="text">Drag and drop files here or <span>browse</span></p>
            </div>
          </label>
        </div>
        <div v-if="fileUploadList.length + documents.length > 10" class="modal-buttons">
          <div v-if="status === 'not_yet_started'">
            <Button
              :disabled="isSubmitting || isSavingForLater"
              class="close lt-txt-color"
              @click="saveForLater"
              label="Save for later"
              :loading="isSavingForLater"
            />
            <Button
              :disabled="isSubmitting || isSavingForLater || (!fileUploadList.length && !documents.length)"
              :data-test-id="getDataTestId('top-submit-uploads-button')"
              label="Submit files"
              type="primary"
              @click="submitFiles"
              :loading="isSubmitting"
            />
          </div>
          <div v-else class="modal-buttons">
            <Button :disabled="isSubmitting" class="close lt-txt-color" @click="saveForLater" label="Cancel" />
            <Button
              :data-test-id="getDataTestId('top-submit-uploads-button')"
              :disabled="isSubmitting || (!fileUploadList.length && !documents.length)"
              label="Submit files"
              type="primary"
              @click="submitFiles"
              :loading="isSubmitting"
            />
          </div>
        </div>
        <ul class="file-list mt-4" v-if="fileUploadList.length || documents.length" v-cloak>
          <li class="text-sm p-1 p-d-flex p-ai-center" v-for="(file, i) of fileUploadList" :key="i">
            <div class="p-d-flex file-container">
              <img :src="require('@/assets/icons/tick_icon.svg')" alt="" />
              <div class="file-details">
                <div class="progress-bar" :id="`progress-${i}`"></div>
                <p class="file-name pm-text-color">{{ file.name }}</p>
                <div v-if="file.size" class="time-size p-d-flex">
                  <p class="size lt-txt-color">
                    <span class="error-class" v-if="file.size > 1_000_000_000">Exceeds maximum file size 1 GB</span>
                    <span v-else>Size: {{ formatFileSize(file.size) }}</span>
                  </p>
                </div>
              </div>
            </div>
            <img
              class="icon"
              @click="removePendingUpload(file)"
              :src="require('@/assets/icons/cross_icon.svg')"
              alt=""
            />
          </li>
          <li class="text-sm p-1 p-d-flex p-ai-center" v-for="document of documents" :key="document.id">
            <div class="p-d-flex file-container">
              <img :src="require('@/assets/icons/tick_icon.svg')" alt="" />
              <div class="file-details">
                <p class="file-name pm-text-color">{{ formatFileName(document.s3FilePath) }}</p>
              </div>
            </div>
            <img
              v-if="status === 'not_yet_started'"
              class="icon"
              @click="removeUploadedFile(document)"
              :src="require('@/assets/icons/cross_icon.svg')"
              alt=""
            />
          </li>
        </ul>
      </div>
      <div class="modal-buttons">
        <div v-if="status === 'not_yet_started'">
          <Button
            :disabled="isSubmitting || isSavingForLater"
            class="close lt-txt-color"
            @click="saveForLater"
            label="Save for later"
            :loading="isSavingForLater"
          />
          <Button
            :data-test-id="getDataTestId('bottom-submit-uploads-button')"
            :disabled="isSubmitting || isSavingForLater || (!fileUploadList.length && !documents.length)"
            label="Submit files"
            type="primary"
            @click="submitFiles"
            :loading="isSubmitting"
          />
        </div>
        <div v-else>
          <Button :disabled="isSubmitting" class="close lt-txt-color" @click="saveForLater" label="Cancel" />
          <Button
            :data-test-id="getDataTestId('bottom-submit-uploads-button')"
            :disabled="isSubmitting || (!fileUploadList.length && !documents.length)"
            label="Submit files"
            type="primary"
            @click="submitFiles"
            :loading="isSubmitting"
          />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { deleteSupportingDocument, uploadFileToS3, uploadSupportingDocuments } from '@/services/fileUploadService';
import { fromSnakeCase } from '@/services/format';
import { mapActions, mapGetters } from 'vuex';
import Dialog from 'primevue/dialog';
import Screensize from '@/mixins/screensize.mixin';

export default {
  mixins: [Screensize],
  components: { Dialog },
  emits: ['skipped', 'completed'],
  props: [
    'display',
    'openDialog',
    'closeDialog',
    'status',
    'taskTitle',
    'crmTaskTitle',
    'externalDescription',
    'taskId',
    'documents',
    'selectedLoanDetail;s',
  ],
  setup() {
    return {
      fromSnakeCase,
    };
  },
  data() {
    return {
      fileUploadList: [],
      isSubmitting: false,
      isSavingForLater: false,
      isLoading: true,
      fileKey: 0,
    };
  },
  computed: {
    ...mapGetters({
      selectedLoanDetails: 'loans/selectedLoanDetails',
    }),
  },
  methods: {
    async created() {
      this.isLoading = true;
      await this.fetchLoan();
      this.isLoading = false;
    },

    ...mapActions({
      fetchLoanSummary: 'loans/fetchLoanSummary',
    }),
    /**
     * Generates a unique id for the element for Playwright
     */
    getDataTestId(key) {
      const crmTaskTitle = this.crmTaskTitle || '';
      let isApplicant, applicantIndex;
      for (const applicant of this.selectedLoanDetails.applicants) {
        isApplicant = crmTaskTitle.endsWith(`${applicant.firstName} ${applicant.lastName}`);
        if (isApplicant) {
          applicantIndex = this.selectedLoanDetails.applicants.indexOf(applicant);
          break;
        }
      }
      if (isApplicant) {
        return 'submit-' + crmTaskTitle + '-applicant-' + applicantIndex + '-' + key;
      }
      let isIncomingProperty, incomingPropertyIndex;
      for (const property of this.selectedLoanDetails.incomingProperties) {
        isIncomingProperty = crmTaskTitle.endsWith(property.line1);
        if (isIncomingProperty) {
          incomingPropertyIndex = this.selectedLoanDetails.incomingProperties.indexOf(property);
          break;
        }
      }
      if (isIncomingProperty) {
        return 'submit-' + crmTaskTitle + '-incoming-property-' + incomingPropertyIndex + '-' + key;
      }
      let isOutgoingProperty, outgoingPropertyIndex;
      for (const property of this.selectedLoanDetails.outgoingProperties) {
        isOutgoingProperty = crmTaskTitle.endsWith(property.line1);
        if (isOutgoingProperty) {
          outgoingPropertyIndex = this.selectedLoanDetails.outgoingProperties.indexOf(property);
          break;
        }
      }
      if (isOutgoingProperty) {
        return 'submit-' + crmTaskTitle + '-outgoing-property-' + outgoingPropertyIndex + '-' + key;
      }
      return 'submit-' + crmTaskTitle + '-' + key;
    },
    /**
     * Event handler for when the file input changes by way
     * of file(s) being selected
     */
    onChange() {
      for (const file of this.$refs.fileV.files) {
        this.fileUploadList.push(file);
      }
    },

    /**
     * Extracts file name from S3 bucket path
     */
    formatFileName(fileName) {
      const fileOnly = fileName.split('/').pop();
      return fileOnly;
    },

    /**
     * Formats file size in KB or MB
     */
    formatFileSize(totalBytes) {
      if (totalBytes < 1_000_000) {
        return (totalBytes / 1000).toFixed(1) + 'KB';
      } else {
        return (totalBytes / 1_000_000).toFixed(1) + 'MB';
      }
    },

    /**
     * Submits the files and leaves the task incomplete
     */
    async saveForLater() {
      await this.uploadFiles(false);
    },

    /**
     * Submits the files and completes the task
     */
    async submitFiles() {
      await this.uploadFiles(true);
    },

    /**
     * Removes a newly-added file from the upload queue
     */
    removePendingUpload(file) {
      const index = this.fileUploadList.indexOf(file);
      if (index > -1) {
        this.fileUploadList.splice(index, 1);
        this.fileKey += 1;
      }
    },

    /**
     * Event handler for entering the container when dragging files
     */
    onDragOver(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.add('bg-green-300');
      }
    },

    /**
     * Event handler for leaving the container when dragging files
     */
    onDragLeave(event) {
      event.currentTarget.classList.remove('bg-green-300');
    },

    /**
     * Event handler for dropping files
     */
    onDrop(event) {
      event.preventDefault();
      this.$refs.fileV.files = event.dataTransfer.files;
      this.onChange();
      event.currentTarget.classList.remove('bg-green-300');
    },

    /**
     * Submits the pending uploads to the backend server to create the
     * signed URLs required for uploading to S3, and optionally "completes"
     * the task by assigning it back to CRM users for review
     */
    async uploadFiles(completeTask) {
      const uploads = this.fileUploadList.filter((file) => file.size < 1_000_000_000);
      if (!uploads.length && !completeTask) {
        return this.$emit('skipped');
      }
      this.isSubmitting = completeTask;
      this.isSavingForLater = !completeTask;
      if (!uploads.length) {
        await uploadSupportingDocuments(this.taskId, [], completeTask);
        return this.$emit('completed');
      }
      let signedUrls;
      try {
        signedUrls = await uploadSupportingDocuments(this.taskId, uploads, false);
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          return this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error.response.data.message,
            life: 3000,
          });
        } else {
          return this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
      }
      if (!signedUrls.length) {
        if (completeTask) {
          await uploadSupportingDocuments(this.taskId, [], true);
        }
        return this.$emit('completed');
      }
      try {
        Promise.all(
          signedUrls.map((signedUrl) => {
            const index = signedUrls.indexOf(signedUrl);
            const file = uploads[index];
            const progressBar = document.getElementById(`progress-${index}`);
            return uploadFileToS3(signedUrl, file, progressBar);
          }),
        ).then(async () => {
          this.isSubmitting = false;
          this.isSavingForLater = false;
          if (completeTask) {
            await uploadSupportingDocuments(this.taskId, [], true);
          }
          this.$emit('completed');
        });
      } catch (error) {
        await this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went wrong',
          life: 3000,
        });
      }
    },

    /**
     * Removes an already-uploaded file from the supporting document
     */
    async removeUploadedFile(document) {
      try {
        const { id, s3FilePath } = document;
        await deleteSupportingDocument(id, s3FilePath);
        this.documents.splice(document, 1);
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error deleting upload',
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

[v-cloak] {
  display: none;
}

.file-list {
  width: 100%;

  h2 {
    font-size: 1rem;
  }

  li {
    width: 100%;
    gap: 1rem;
    justify-content: space-between;
    border-bottom: 1px solid $light-text-color;

    .progress-bar {
      height: 0.25rem;
      line-height: 0.25rem;
      display: block;
      width: 0;
      background-color: $primary-color;
    }

    .file-details {
      flex-grow: 1;
    }

    .file-container {
      flex-grow: 1;
      gap: 1rem;
      padding: 1rem 0;
      align-items: center;
    }

    .file-name {
      margin: 0;
      font-size: 0.875rem;
    }

    .icon {
      cursor: pointer;
    }
  }

  li:last-of-type {
    border-bottom: none;

    div {
      padding-bottom: 0;
    }
  }
}

.title {
  font-size: 1.25rem;
}

.content {
  .heading {
    font-size: 0.875rem;
  }
}

.file {
  margin: 1.5rem 0 1rem 0;

  .icon-text {
    gap: 1rem;

    .text {
      margin: 0;
      font-size: 0.875rem;

      span {
        color: $primary-color;
      }
    }
  }
}

.modal-buttons {
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 992px) {
    // gap: 0;
    justify-content: space-between;
  }

  .close {
    background-color: #fff;
    border-color: #fff;
    cursor: pointer;
  }
  .close:hover {
    background-color: #fff;
    border-color: #fff;
    color: #061238 !important;
  }
}

.upload-box {
  width: 100%;
  border: 2px dashed #54b7f9;
  background: #54b7f91a;
  border-radius: 5px;
  display: block;
  padding: 1rem;
  transition: border 300ms ease;
  cursor: pointer;
  text-align: center;
}

.bg-green-300 {
  background-color: #ffffff !important;
}
</style>
